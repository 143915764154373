<template>
  <div class="town">
    <div class="view-title">
      <div class="brand-title">村镇配置</div>
      <div class="btns">
        <button class="btn iszhen" @click="addZhen">添加乡镇</button>
        <button class="btn iszhen" @click="addArea">添加片区</button>
        <button class="btn iszhen iscun" @click="addCun">添加村庄</button>
      </div>
    </div>
    <el-tabs @tab-click="handleClick" v-model="taskShow">
      <el-tab-pane label="镇" name="1"></el-tab-pane>
      <el-tab-pane label="核心片区" name="2"></el-tab-pane>
      <el-tab-pane label="村" name="3"></el-tab-pane>
    </el-tabs>
    <MyTable
      v-show="taskShow == '1'"
      class="animated fadeInUp"
      :tableData="townList"
      :configTable="zhenTable"
      @handleEdit="handleZhenEdit"
      @handleDelete="delItems"
    ></MyTable>
    <MyTable
      v-show="taskShow == '2'"
      class="animated fadeInUp"
      :tableData="scopeList"
      :configTable="pianquTable"
      @handleDelete="delScopeItems"
      @handleEdit="handleEditScope"
      @handleDrawn="handleDrawn"
    ></MyTable>
    <MyTable
      v-show="taskShow == '3'"
      class="animated fadeInUp"
      :tableData="villageList"
      @handleDelete="delCunItems"
      @handleEdit="handleCunEdit"
      :configTable="cunTable"
    ></MyTable>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      :current-page="pn"
      :page-size="ps"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <el-dialog
      class="small-pop"
      title=" "
      :visible.sync="zhenShow"
      width="659px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="pop-small">
        <div class="ware-title">
          <div class="left-line"></div>
          {{ PopTitle }}
        </div>
        <el-form
          class="forms"
          ref="zhenForm"
          :model="zhenForm"
          :rules="batchRules"
          hide-required-asterisk
        >
          <el-form-item label="名称" :label-width="formLabelWidth" prop="name">
            <el-input
              v-model.trim="zhenForm.name"
              autocomplete="off"
              placeholder="请输入乡镇名称"
              maxlength="20"
            ></el-input>
          </el-form-item>

          <el-form-item label=" " class="dialog-footer">
            <el-button
              class="ok-button"
              type="primary"
              @click="okWarehouse('zhenForm')"
              >确 定</el-button
            >
            <el-button class="no-button" @click="noWarehouse('zhenForm')"
              >取 消</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog
      class="big-pop"
      title=" "
      :visible.sync="areaShow"
      width="596px"
      :before-close="setAreaClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="big-main">
        <div class="ware-title">
          <div class="left-line"></div>
          {{ areaPopTitle }}
        </div>
        <div class="add-form">
          <el-form
            :model="scopeForm"
            ref="scopeForm"
            :rules="scopeRules"
            hide-required-asterisk
          >
            <el-form-item
              label="所属乡镇"
              :label-width="formLabelWidth"
              prop="town_id"
            >
              <el-select v-model="scopeForm.town_id" placeholder="选择乡镇">
                <el-option
                  :label="item.town_name"
                  :value="item.town_id"
                  v-for="(item, index) in townList"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="名称"
              :label-width="formLabelWidth"
              prop="scope_name"
            >
              <el-input
                v-model.trim="scopeForm.scope_name"
                autocomplete="off"
                placeholder="请输入片区名称"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item label=" " class="dialog-footer">
              <el-button
                class="ok-button"
                type="primary"
                @click="okAddScope('scopeForm')"
                >确 定</el-button
              >
              <el-button class="no-button" @click="noAddScope('scopeForm')"
                >取 消</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      class="big-pop"
      title=" "
      :visible.sync="cunShow"
      width="596px"
      :before-close="setZhenClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="big-main">
        <div class="ware-title">
          <div class="left-line"></div>
          {{ bigPopTitle }}
        </div>
        <div class="add-form">
          <el-form
            :model="cunForm"
            ref="cunForm"
            :rules="cunRules"
            hide-required-asterisk
          >
            <el-form-item
              label="片区"
              :label-width="formLabelWidth"
              prop="town_id"
            >
              <el-select
                v-model="cunForm.scope_id"
                placeholder="选择片区"
                v-loadMore="loadMoreScopeData"
              >
                <el-option
                  :label="item.scope_name"
                  :value="item.scope_id"
                  v-for="(item, index) in scopeList2"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="名称"
              :label-width="formLabelWidth"
              prop="village_name"
            >
              <el-input
                v-model.trim="cunForm.village_name"
                autocomplete="off"
                placeholder="请输入村庄名称"
                maxlength="20"
              ></el-input>
            </el-form-item>

            <el-form-item
              label="人员"
              :label-width="formLabelWidth"
              prop="user_name"
            >
              <el-input
                v-model.trim="cunForm.user_name"
                autocomplete="off"
                placeholder="请输入人员"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="电话"
              :label-width="formLabelWidth"
              prop="phone"
            >
              <el-input
                v-model.number.trim="cunForm.phone"
                autocomplete="off"
                placeholder="请输入电话"
                maxlength="20"
              ></el-input>
            </el-form-item>

            <el-form-item label=" " class="dialog-footer">
              <el-button
                class="ok-button"
                type="primary"
                @click="okAddCun('cunForm')"
                >确 定</el-button
              >
              <el-button class="no-button" @click="noAddCun('cunForm')"
                >取 消</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  postTownAddApi,
  getTownListApi,
  postVillageAddApi,
  postTownEditApi,
  delTownApi,
  delVillageApi,
  getVillageListApi,
  getVillageInfoApi,
  postVillageEditApi,
  postAddScopeListApi,
  postEditScopeListApi,
  getScopeListApi,
  postDelScopeListApi,
} from "@/request/api";
import { mapState } from "vuex";
export default {
  components: {
    MyTable: () => import("@/components/MyTable.vue"),
    // MyNothing: () => import("@/components/MyNothing.vue"),
  },
  data() {
    return {
      taskShow: "1",
      total: 0,
      ps: 10,
      pn: 1,
      scopeTotal: 0,
      scopePs: 10,
      scopePn: 1,
      scopeList: [],
      villageList: [],
      scopeList2: [],
      zhenShow: false,
      cunShow: false,
      areaShow: false,
      PopTitle: "添加乡镇",
      bigPopTitle: "添加村庄",
      areaPopTitle: "添加片区",
      editId: "",
      cunForm: {
        village_name: "",
        user_name: "",
        phone: "",
        scope_id: "",
      },
      zhenForm: {
        town_name: "",
      },
      scopeForm: {
        town_id: "",
        scope_name: "",
      },

      zhenTable: [
        {
          prop: "town_name",
          label: "名称",
          width: "",
        },
        {
          prop: "",
          label: "操作",
          width: "300",
          delType: true,
          editType: true,
        },
      ],
      pianquTable: [
        {
          prop: "scope_name",
          label: "名称",
          width: "",
        },
        {
          prop: "",
          label: "操作",
          width: "300",
          delType: true,
          editType: true,
          drawnType: true,
        },
      ],
      cunTable: [
        {
          prop: "village_name",
          label: "名称",
          width: "",
        },
        {
          prop: "",
          label: "操作",
          width: "300",
          delType: true,
          editType: true,
        },
      ],
      batchRules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
      },
      scopeRules: {
        scope_name: [
          { required: true, message: "请输入片区名称", trigger: "blur" },
        ],

        town_id: [{ required: true, message: "请选择乡镇", trigger: "change" }],
      },
      cunRules: {
        village_name: [
          { required: true, message: "请输入村庄名称", trigger: "blur" },
        ],
        user_name: [
          { required: false, message: "请输入人员", trigger: "blur" },
        ],
        phone: [
          {
            pattern:
              /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: "手机格式错误",
            trigger: "blur",
          },
        ],
        scope_id: [
          { required: true, message: "请选择片区", trigger: "change" },
        ],
      },
      formLabelWidth: "60px",
      townList: [],
    };
  },

  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
    }),
  },
  created() {
    this.getTownListApi();
    this.getScopeListApi2();
  },
  methods: {
    loadMoreScopeData() {
      if (this.scopeList.length && this.scopeList.length == this.scopeTotal)
        return;
      this.scopePn++;
      this.getScopeListApi2(this.scopePn);
    },
    handleClick(tab) {
      this.taskShow = tab.name;
      this.ps = 10;
      this.total = 0;
      this.pn = 1;
      if (tab.name == "1") {
        this.getTownListApi();
      } else if (tab.name == "2") {
        this.getScopeListApi();
      } else {
        this.getVillageListApi();
      }
    },
    async getScopeListApi2(pn = 1) {
      const { code, results } = await getScopeListApi({ pn });
      if (code != 0) return;
      this.scopeTotal = results.count;
      this.scopePs = results.ps;
      this.scopePn = results.pn;
      this.scopeList2 = [...this.scopeList2, ...results.data];
    },
    async getScopeListApi(pn = 1) {
      const { code, results } = await getScopeListApi({ pn });
      if (code != 0) return;
      this.total = results.count;
      this.ps = results.ps;
      this.pn = results.pn;
      this.scopeList = results.data;
    },
    async postAddScopeListApi() {
      const { code } = await postAddScopeListApi(this.scopeForm);
      if (code != 0) return;
      this.$notify({
        title: "提示",
        message: "已添加",
        type: "success",
      });
    },
    async postEditScopeListApi() {
      const { code } = await postEditScopeListApi(this.scopeForm);
      if (code != 0) return;
      this.$notify({
        title: "提示",
        message: "已编辑",
        type: "success",
      });
    },

    async getVillageInfoApi(village_id) {
      const { code, results } = await getVillageInfoApi({
        village_id,
      });
      if (code !== 0) return;
      this.bigPopTitle = "编辑村庄";
      this.cunForm = results;
      this.cunShow = true;
      // this.cunForm.name = this.cunForm.village_name;
      // this.$forceUpdate();
    },
    handleEditScope(row) {
      this.scopeForm = row;
      this.areaPopTitle = "编辑片区";
      this.areaShow = true;
    },
    handleZhenEdit(row) {
      this.zhenForm = row;
      this.PopTitle = "编辑乡镇";
      this.zhenShow = true;
    },
    handleCunEdit(row) {
      this.getVillageInfoApi(row.village_id);
    },
    delCunItems({ village_id }) {
      this.$confirm(`确认删除该村庄？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await delVillageApi({
            village_id,
          });
          if (res.code != 204) {
            this.$notify.error({
              title: "错误",
              message: res.msg,
            });
          } else {
            this.$notify({
              title: "提示",
              message: "已删除",
              type: "success",
            });
          }
          this.getVillageListApi();
        })
        .catch(() => {});
    },
    handleCurrentChange(val) {
      this.pn = val;
      if (this.taskShow == "1") {
        this.getTownListApi(val);
      } else if (this.taskShow == "2") {
        this.getScopeListApi(val);
      } else {
        this.getVillageListApi(val);
      }
    },
    delScopeItems({ scope_id }) {
      this.$confirm(`确认删除该片区？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await postDelScopeListApi({
            scope_id,
          });
          if (res.code != 204) {
            this.$notify.error({
              title: "错误",
              message: res.msg,
            });
          } else {
            this.$notify({
              title: "提示",
              message: "已删除",
              type: "success",
            });
          }
          this.getScopeListApi();
        })
        .catch(() => {});
    },
    delItems({ town_id }) {
      this.$confirm(`确认删除该乡镇？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await delTownApi({
            town_id,
          });
          if (res.code != 204) {
            this.$notify.error({
              title: "错误",
              message: res.msg,
            });
          } else {
            this.$notify({
              title: "提示",
              message: "已删除",
              type: "success",
            });
          }
          this.getTownListApi();
        })
        .catch(() => {});
    },
    async getTownListApi(pn = 1) {
      const { code, results } = await getTownListApi({
        manor_id: this.farmID,
        ps: this.ps,
        pn,
      });
      if (code != 0) return;
      this.townList = results.data;
    },
    async getVillageListApi(pn = 1) {
      const { code, results } = await getVillageListApi({
        pn,
      });
      if (code !== 0) return;
      this.total = results.count;
      this.ps = results.ps;
      this.pn = results.pn;
      this.villageList = results.data;
      // this.townList[index]["village"] = results.data;
      // this.$forceUpdate();
    },
    handleDrawn({ scope_id }) {
      this.$router.push({
        name: "area",
        query: {
          id: scope_id,
        },
      });
    },
    async postTownAddApi() {
      const { code } = await postTownAddApi({
        manor_id: this.farmID,
        ...this.zhenForm,
      });
      if (code !== 0) return;
      this.getTownListApi();
      this.$notify({
        title: "成功",
        message: "已添加",
        type: "success",
      });
      this.zhenShow = false;
    },
    async postTownEditApi() {
      const { code } = await postTownEditApi({
        ...this.zhenForm,
      });
      if (code !== 0) return;
      this.getTownListApi();
      this.$notify({
        title: "成功",
        message: "已修改",
        type: "success",
      });
      this.zhenShow = false;
    },
    async postVillageEditApi() {
      const { code } = await postVillageEditApi(this.cunForm);
      if (code !== 0) return;
      this.getVillageListApi();
      this.$notify({
        title: "成功",
        message: "已修改",
        type: "success",
      });
      this.cunShow = false;
    },
    async postVillageAddApi() {
      const { code } = await postVillageAddApi({
        village_name: this.cunForm.village_name,
        village_id: this.cunForm.village_id,
        scope_id: this.cunForm.scope_id,
        user_name: this.cunForm.user_name,
        phone: this.cunForm.phone,
      });
      if (code !== 0) return;
      this.getVillageListApi();
      this.$notify({
        title: "成功",
        message: "已添加",
        type: "success",
      });
      this.cunForm = {};
      this.$refs["cunForm"].resetFields();
      this.cunShow = false;
    },
    setAreaClose() {
      this.areaShow = false;
      this.scopeForm = {};
    },
    setZhenClose() {
      this.cunShow = false;
      this.cunForm = {};
    },
    noAddScope(formName) {
      this.areaShow = false;
      this.scopeForm = {};
      this.$refs[formName].resetFields();
    },
    noAddCun(formName) {
      this.cunShow = false;
      this.cunForm = {};
      this.$refs[formName].resetFields();
    },
    noAdd(formName) {
      this.cunShow = false;
      this.cunForm = {};
      this.$refs[formName].resetFields();
    },
    okAddScope(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.areaPopTitle === "添加片区") {
            this.postAddScopeListApi();
          } else {
            this.postEditScopeListApi();
          }
          this.areaShow = false;
        } else {
          return false;
        }
      });
    },
    okAddCun(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.bigPopTitle === "添加村庄") {
            this.postVillageAddApi();
          } else {
            this.postVillageEditApi();
          }
        } else {
          return false;
        }
      });
    },
    addZhen() {
      this.zhenForm = {};
      this.PopTitle = "添加乡镇";
      this.zhenShow = true;
    },
    addArea() {
      this.scopeForm = {};
      this.areaPopTitle = "添加片区";
      this.areaShow = true;
    },
    addCun() {
      this.bigPopTitle = "添加村庄";
      this.cunShow = true;
    },
    noWarehouse(formName) {
      this.zhenShow = false;
      this.$refs[formName].resetFields();
      this.getTownListApi();
    },
    okWarehouse(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.PopTitle === "添加乡镇") {
            this.postTownAddApi();
          } else {
            this.postTownEditApi();
          }
        } else {
          return false;
        }
      });
    },
    handleClose() {
      this.zhenShow = false;
      this.zhenForm = {
        name: "",
        purposeVal: "",
      };
    },
  },
};
</script>

<style lang="less" scoped>
.town {
  width: calc(100% - 450px);
  flex-direction: column;
  padding: 22px 45px;
  background: #0e2138;
  display: flex;
  .view-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    .brand-title {
      font-size: 22px;
      font-weight: bold;
      color: #fff;
      position: relative;
      padding-left: 15px;
      margin-bottom: 35px;
      &::before {
        content: "";
        width: 4px;
        height: 22px;
        background: #3e90e5;
        border-radius: 2px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
      }
    }
    .btns {
      .btn {
        cursor: pointer;
        width: 120px;
        height: 40px;
        border-radius: 5px;
        text-align: center;
        line-height: 40px;
        color: #fff;
        font-size: 14px;
        &:hover {
          opacity: 0.8;
        }
        &.iszhen {
          margin-right: 15px;
          background: #3e90e5;
          border: 1px solid #3e90e5;
        }
        &.iscun {
          margin-right: 0;
        }
      }
    }
  }
  /deep/ .el-tabs__item {
    color: #fff;
  }
  .town-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    .town-item {
      width: 100%;
      border: 1px solid #385576;
      margin-bottom: 74px;
      .town-title {
        width: 100%;
        height: 61px;
        background: #092139;
        font-size: 14px;
        line-height: 61px;
        text-align: center;
        color: #fff;
        position: relative;
        border-bottom: 1px solid #385576;

        .icons {
          display: flex;
          position: absolute;
          top: 50%;
          right: 22px;
          transform: translateY(-50%);
          .icon-imgs {
            width: 20px;
            height: 20px;
            margin-left: 9px;
            cursor: pointer;
          }
        }
      }
      .town-hamlets {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .town-hamlets-items {
          width: 12.5%;
          padding: 0 12px;
          height: 60px;
          background-color: #071a2c;
          border-right: 1px solid #385576;
          display: flex;
          align-items: center;
          &:nth-of-type(8n) {
            border-right: none;
          }
          p {
            width: 70%;
            font-size: 14px;
            color: #fff;
          }
          .icons {
            display: flex;
            // position: absolute;
            // top: 50%;
            // right: 22px;
            // transform: translateY(-50%);
            .icon-imgs {
              width: 20px;
              height: 20px;
              margin-left: 9px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .small-pop,
  .big-pop {
    /deep/.el-dialog {
      width: 666px !important;
      background-color: inherit;
    }

    /deep/.el-dialog__body {
      padding: 0;
    }

    /deep/.el-dialog__headerbtn {
      font-size: 26px;
      top: 64px;
      right: 50px;
      font-weight: bold;
    }
  }

  .pop-small {
    width: 659px;
    height: 371px;
    background: url("../../assets/image/configure/town/cun_bg.png") no-repeat
      center center;
    background-size: 100% 100%;
    padding: 45px 55px 0 55px;

    .ware-title {
      font-size: 22px;
      color: #fff;
      font-weight: bold;
      display: flex;
      margin-bottom: 55px;
      align-items: center;

      .left-line {
        background-color: #fff;
        width: 4px;
        height: 21px;
        border-radius: 3px;
        margin-right: 10px;
      }
    }
    /deep/.el-input__inner {
      background-color: #081c30;
      border: 1px solid #385576;
      color: #57708d;
    }
    /deep/.el-form-item__label {
      color: #fff;
    }
    /deep/.el-button span {
      color: #fff;
    }

    /deep/.dialog-footer {
      .el-form-item__content {
        display: flex;
        justify-content: center;
      }
    }

    .ok-button {
      width: 150px;
      height: 40px;
      margin-right: 44px;
    }

    .no-button {
      width: 150px;
      height: 40px;
      border: 1px solid #385576;
      background-color: #0f243d;
    }
  }

  .big-pop {
    /deep/.el-form-item {
      margin-bottom: 32px;
    }

    /deep/.el-button span {
      color: #fff;
    }

    /deep/.el-dialog__headerbtn {
      top: 71px;
      right: 104px;
    }

    /deep/.dialog-footer {
      margin-top: 40px;
      .el-form-item__content {
        display: flex;
        justify-content: center;
      }
    }

    .ok-button {
      width: 150px;
      height: 40px;
      margin-right: 44px;
    }

    .no-button {
      width: 150px;
      height: 40px;
      border: 1px solid #385576;
      background-color: #0f243d;
    }

    .big-main {
      width: 596px;
      height: 564px;
      background: url("../../assets/image/configure/town/zhen_bg.png") no-repeat
        center center;
      background-size: 100% 100%;
      padding: 45px 55px;
      overflow: hidden;
      .ware-title {
        font-size: 22px;
        color: #fff;
        font-weight: bold;
        display: flex;
        margin-bottom: 40px;
        align-items: center;
        .left-line {
          background-color: #fff;
          width: 4px;
          height: 21px;
          border-radius: 3px;
          margin-right: 10px;
        }
      }

      .block {
        width: 100%;
        height: calc(100% - 124px);
        overflow: auto;
        padding: 0 2px;

        &::-webkit-scrollbar {
          width: 1px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #ccc;
        }
      }

      .info-col1 {
        margin-bottom: 16px;

        span {
          font-size: 18px;
          color: #fff;
          margin-right: 16px;
        }

        .num {
          color: #e60000;
        }

        .num.active {
          color: #67c23a;
        }
      }

      .info-col2 {
        span {
          font-size: 14px;
        }

        .text1 {
          color: #57708d;
          margin-right: 14px;
        }

        .text2 {
          color: #fff;
          margin-right: 20px;
        }

        .text3 {
          color: #57708d;
          margin-right: 10px;
        }

        .text4 {
          color: #3e90e5;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      /deep/.el-input__inner {
        background-color: #081c30;
        border: 1px solid #385576;
        color: #57708d;
        width: 353px;
      }

      /deep/.el-textarea__inner {
        background-color: #081c30;
        border: 1px solid #385576;
        color: #57708d;
        width: 353px;
        height: 120px;
        resize: none;
      }

      /deep/.el-form-item__label {
        font-size: 14px;
        color: #fff;
        padding: 0 24px 0 0;
      }

      /deep/.el-radio__input.is-checked + .el-radio__label {
        color: #fff;
      }

      /deep/.el-radio {
        color: #fff;
      }
      .add-form {
        width: 100%;
        height: calc(100% - 56px);
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 7px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #3e90e5;
        }
      }
    }
  }
  .small-pop {
    /deep/.el-dialog {
      width: 659px !important;
      .el-form-item {
        margin-bottom: 52px;
      }
    }
  }
}
</style>
